define("discourse/plugins/mentorfy-quick-posts/discourse/connectors/after-topic-list-item/quick-posts", ["exports", "@glimmer/component", "discourse/plugins/mentorfy-quick-posts/discourse/components/quick-posts", "@ember/component", "@ember/template-factory"], function (_exports, _component, _quickPosts, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuickPostsConnector extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless @outletArgs.useMobileLayout}}
          <tr class="topic-list-item-quick-posts">
            <td colspan="6">
              <QuickPosts @topic={{@outletArgs.topic}} />
            </td>
          </tr>
        {{/unless}}
      
    */
    {
      "id": "jqj/MTHZ",
      "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"useMobileLayout\"]]],[[[1,\"      \"],[10,\"tr\"],[14,0,\"topic-list-item-quick-posts\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"6\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/mentorfy-quick-posts/discourse/connectors/after-topic-list-item/quick-posts.js",
      "scope": () => [_quickPosts.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = QuickPostsConnector;
});