define("discourse/plugins/mentorfy-quick-posts/discourse/connectors/topic-area-bottom/topic-quick-posts", ["exports", "@glimmer/component", "discourse/plugins/mentorfy-quick-posts/discourse/components/quick-posts", "@ember/component", "@ember/template-factory"], function (_exports, _component, _quickPosts, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicQuickPostsConnector extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="topic-quick-posts-container">
          <QuickPosts @topic={{@outletArgs.model}} />
        </div>
      
    */
    {
      "id": "oP6vaM8E",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"topic-quick-posts-container\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"model\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/mentorfy-quick-posts/discourse/connectors/topic-area-bottom/topic-quick-posts.js",
      "scope": () => [_quickPosts.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicQuickPostsConnector;
});