define("discourse/plugins/mentorfy-quick-posts/discourse/models/quick-post", ["exports", "discourse/lib/model-transformers", "discourse/models/rest"], function (_exports, _modelTransformers, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.registerQuickPostModel = registerQuickPostModel;
  class QuickPost extends _rest.default {
    createProperties() {
      return {
        raw: this.raw,
        topic_id: this.topic_id,
        reply_to_post_number: this.reply_to_post_number
      };
    }
  }

  // Registrando o modelo e adaptador
  _exports.default = QuickPost;
  function registerQuickPostModel() {
    (0, _modelTransformers.registerModelTransformer)("quick-post", {
      model: QuickPost,
      adapter: {
        pathFor(store, type, findArgs) {
          const topicId = findArgs.topic_id;
          const page = findArgs.page ? `page=${findArgs.page}` : "";
          return `/t/${topicId}/quick_posts${page ? `?${page}` : ""}`;
        },
        findAll(store, type, findArgs) {
          const path = this.pathFor(store, type, findArgs);
          return this.ajax(path).then(result => {
            return {
              content: result.quick_posts || [],
              meta: {
                total_posts: result.total_posts,
                current_page: result.current_page
              }
            };
          });
        },
        createRecord(store, type, attrs) {
          return this.ajax(`/t/${attrs.topic_id}/quick_posts`, {
            type: "POST",
            data: attrs
          }).then(json => {
            if (!json) {
              return null;
            }

            // Garante que os dados do usuário estão presentes
            if (!json.user) {
              json.user = store.currentUser;
            }
            return json;
          });
        }
      }
    });
  }
});