define("discourse/plugins/mentorfy-quick-posts/discourse/initializers/quick-posts", ["exports", "discourse/lib/plugin-api", "discourse/plugins/mentorfy-quick-posts/discourse/models/quick-post", "discourse/plugins/mentorfy-quick-posts/discourse/components/quick-posts"], function (_exports, _pluginApi, _quickPost, _quickPosts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "quick-posts",
    initialize() {
      (0, _pluginApi.withPluginApi)("2.1.0", api => {
        // Registra o modelo e adaptador
        (0, _quickPost.registerQuickPostModel)(api);

        // Renderiza o componente no outlet da lista de tópicos
        api.renderInOutlet("topic-list-after-row", _quickPosts.default);

        // Renderiza o componente no outlet do final do tópico
        api.registerConnectorClass("topic-area-bottom", "topic-quick-posts");

        // Adiciona classe ao body quando quick-posts está habilitado
        if (api.container.lookup("service:site-settings").enable_quick_posts) {
          document.body.classList.add("quick-posts-enabled");
        }
      });
    }
  };
});